<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="success"
          inline
          title="Édition d'un scénario"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="scénario.identifiant"
                  label="Identifiant"
                  data-cy="tf-identifiant"
                />
                <i18n-text-field
                  :value.sync="scénario.nom"
                  label="Nom"
                  data-cy="tf-nom"
                />
                <v-checkbox
                  v-model="scénario.actif"
                  label="Actif"
                  data-cy="cb-actif"
                />
              </v-col>
              <v-col md="6">
                <i18n-cnx-editeur
                  :texte.sync="scénario.description"
                  label="Historique"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="primary"
              data-cy="bt-sauvegarder"
              @click="onSauvegarder()"
            >
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        md="12"
        lg="6"
      >
        <base-material-card
          color="success"
          inline
          title="Les fiches"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-row>
              <v-select
                v-model="sélectionFiche"
                :items="scénario.listeFiches"
                item-text="identifiant"
                item-value="id"
                return-object
                data-cy="lb-fiches"
                @change="onChangerSélectionFiche()"
              />
              <v-spacer />
              <v-text-field
                v-model="sélectionFiche.poids"
                style="width: 70px"
                type="number"
                label="Ordre"
                :min="0"
                filled

                data-cy="tf-fiche-identifiant"
              />
              <v-spacer />
              <v-btn
                class="primary"
                data-cy="bt-créerFiche"
                @click="onCréerFiche()"
              >
                Créer une nouvelle fiche
              </v-btn>
            </v-row>
            <v-card
              v-if="sélectionFiche"
              data-cy="zoneEditionFiche"
            >
              <v-card-text>
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      v-model="sélectionFiche.identifiant"
                      label="Identifiant"
                      data-cy="tf-fiche-identifiant"
                    />
                    <i18n-combobox
                      ref="categorieFiche"
                      :value.sync="sélectionFiche.categorie"
                      :items="listeCategorie"
                      label="Catégorie"
                      prepend-icon="mdi-translate"
                    />
                    <i18n-text-field
                      :value.sync="sélectionFiche.titre"
                      label="Titre"
                      data-cy="tf-fiche-titre"
                    />
                  </v-col>
                  <v-col md="6">
                    <v-checkbox
                      v-model="etatFiche"
                      label="Visible"
                      data-cy="cb-fiche-visible"
                    />
                    <v-text-field
                      v-model="sélectionFiche.pointsConsultation"
                      label="Points pour consultation"
                      data-cy="tf-fiche-points"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      v-model="choixTypeContenuFiche"
                      :items="typeContenuFiche"
                      label="Type de contenu"
                      item-value="id"
                      item-text="text"
                      @change="onChangerChoixTypeContenuFiche"
                    />
                  </v-col>
                </v-row>
                <v-text-field
                  v-if="choixTypeContenuFiche == 1"
                  v-model="sélectionFiche.urlVideo"
                  placeholder="Url de la vidéo"
                  hint="* La vidéo ne devrait pas dépasser 30 secondes et au format de 320x200"
                  persistent-hint
                />
                <v-row v-if="choixTypeContenuFiche == 0">
                  <v-col>
                    <i18n-cnx-editeur
                      :texte.sync="sélectionFiche.texte"
                      label="Texte"
                    />
                  </v-col>
                </v-row>
                <v-row class="mt-4">
                  <v-col>
                    <v-btn
                      class="primary"
                      data-cy="bt-fiche-sauvegarder"
                      @click="onSauvegarderFiche()"
                    >
                      Sauvegarder
                    </v-btn>
                    <v-btn
                      class="warning"
                      data-cy="bt-fiche-effacer"
                      @click="onEffacerFiche()"
                    >
                      Effacer
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </base-material-card>
        <base-material-card
          color="success"
          inline
          title="Décision"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-textarea
              v-model="action.texte"
              label="Texte"
            />
            <v-btn
              class="primary mt-4 mb-3"
              data-cy="bt-action-sauvegarder"
              @click="onSauvegarderAction()"
            >
              Sauvegarder
            </v-btn>

            <v-row>
              <v-select
                v-model="sélectionOption"
                label="Choix"
                item-text="identifiant"
                return-object
                :items="action.listeOptions"
                data-cy="lb-options"
              />
              <v-spacer />
              <v-btn
                class="primary"
                data-cy="bt-action-creerOption"
                @click="onCréerOption()"
              >
                Ajouter un choix
              </v-btn>
            </v-row>
            <v-card
              v-if="sélectionOption"
              outlined
              data-cy="zoneEditionOption"
            >
              <v-card-text>
                <v-text-field
                  v-model="sélectionOption.identifiant"
                  label="Identifiant"
                  data-cy="action-option-identifiant"
                />
                <i18n-text-field
                  :value.sync="sélectionOption.texte"
                  label="Texte"
                  data-cy="action-option-texte"
                />
                <v-text-field
                  v-model="sélectionOption.points"
                  label="Points"
                  data-cy="action-option-points"
                />
                <v-text-field
                  v-model="sélectionOption.scriptReactionDecision"
                  label="Script"
                  data-cy="action-option-script"
                />
                <v-text-field
                  v-model="sélectionOption.navigation"
                  label="Navigation"
                  data-cy="action-option-navigation"
                />
                <i18n-cnx-editeur
                  :texte.sync="sélectionOption.retroaction"
                  label="Rétroaction"
                />
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-btn
              data-cy="bt-option-effacer"
              @click="onEffacerOption()"
            >
              Effacer ce choix
            </v-btn>
            <v-spacer />
            <v-btn
              class="primary"
              data-cy="action-option-sauvegarder"
              @click="onSauvegarderOption()"
            >
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-col>
      <v-col
        md="12"
        lg="6"
      >
        <base-material-card
          color="success"
          inline
          title="Configuration de la scène"
          class="px-5 py-3"
        >
          <v-card-text>
            <v-select
              v-model="scénario.scene"
              outlined
              label="Scène"
              item-text="identifiant"
              item-value="identifiant"
              :items="listeScenes"
              return-object
              @input="onSauvegarder()"
            />
            <hr>
            <br>
            <v-row>
              <v-col md="12">
                <v-select
                  v-model="accessoireECG"
                  outlined
                  label="Moniteur ECG"
                  item-text="nom"
                  item-value="identifiant"
                  :items="listeAppareilsECG"
                  return-object
                  @input="onSauvegarderAppareilECG()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-select
                  v-model="accessoireVentilation"
                  outlined
                  label="Appareil de ventilation"
                  item-text="nom"
                  item-value="identifiant"
                  :items="listeAppareilsVentilation"
                  return-object
                  @input="onSauvegarderAppareilVentilation()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-select
                  v-model="accessoirePompe"
                  outlined
                  label="Pompe à soluté"
                  item-text="nom"
                  item-value="identifiant"
                  :items="listeAppareilsPompe"
                  return-object
                  @input="onSauvegarderAppareilPompe()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-select
                  v-model="accessoirePatient"
                  outlined
                  label="Patient"
                  item-text="nom"
                  item-value="identifiant"
                  return-object
                  :items="listeAppareilsPatient"
                  @input="onSauvegarderAppareilPatient()"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import restApiService from '@/services/restApiService.js'
  import I18nTextField from '../composants/I18nTextField.vue'
  import I18nCnxEditeur from '../composants/I18nCnxEditeur.vue'
  import i18nCombobox from '../composants/I18nCombobox.vue'

  export default {
    components: {
      I18nTextField,
      I18nCnxEditeur,
      i18nCombobox,
    },
    data: () => ({
      scénario: {},
      sélectionFiche: {},
      sélectionOption: null,
      action: {},
      listeScenes: [],
      listeAppareilsECG: [],
      listeAppareilsVentilation: [],
      listeAppareilsPompe: [],
      listeAppareilsPatient: [],
      listeCategorie: [],
      accessoireECG: {},
      accessoireVentilation: {},
      accessoirePompe: {},
      accessoirePatient: {},
      typeContenuFiche: [
        {
          id: 0,
          text: 'Texte',
        },
        {
          id: 1,
          text: 'Vidéo',
        },
      ],
      choixTypeContenuFiche: '',
    }),
    computed: {
      etatFiche: {
        get () {
          if (this.sélectionFiche.etat === 'afficher') {
            return true
          } else {
            return false
          }
        },
        set (value) {
          if (value === true) {
            this.sélectionFiche.etat = 'afficher'
          } else {
            this.sélectionFiche.etat = 'masquer'
          }
        },
      },
    },
    mounted () {
      restApiService.get('/api/scenes/').then((result) => {
        this.listeScenes = result.data
      })
      this.chargerScénario()
    },
    methods: {
      chargerAccessoires () {
        restApiService.get('/api/objet-interactifs/type/Moniteur ECG').then((result) => {
          this.listeAppareilsECG = result.data
          restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Moniteur ECG').then((result) => {
            this.accessoireECG = result.data.objetInteractif
          })
        })
        restApiService.get('/api/objet-interactifs/type/Appareil de ventilation').then((result) => {
          this.listeAppareilsVentilation = result.data
          restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Appareil de ventilation').then((result) => {
            this.accessoireVentilation = result.data.objetInteractif
          })
        })
        restApiService.get('/api/objet-interactifs/type/Pompe à soluté').then((result) => {
          this.listeAppareilsPompe = result.data
          restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Pompe à soluté').then((result) => {
            this.accessoirePompe = result.data.objetInteractif
          })
        })
        restApiService.get('/api/objet-interactifs/type/Patient').then((result) => {
          this.listeAppareilsPatient = result.data
          restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Patient').then((result) => {
            this.accessoirePatient = result.data.objetInteractif
          })
        })
      },
      chargerScénario () {
        restApiService.get('/api/scenarios/' + this.$route.params.id).then((result) => {
          this.scénario = result.data
          this.action = this.scénario.listeActions[0]
          if (this.scénario.listeFiches.length > 0) {
            this.sélectionFiche = this.scénario.listeFiches[0]
            restApiService.get('/api/fiches/categories').then((cResult) => {
              this.listeCategorie = cResult.data
            })
            this.onChangerSélectionFiche()
          }
          if (this.action.listeOptions.length > 0) {
            this.sélectionOption = this.action.listeOptions[0]
          }
          this.chargerAccessoires()
        })
      },
      onSauvegarderAppareilECG () {
        restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Moniteur ECG').then((result) => {
          var obj = result.data
          if (obj === '') {
            obj = {}
            obj.scenario = this.scénario
            obj.objetInteractif = this.accessoireECG
            restApiService.post('/api/accessoires', obj)
          } else {
            obj.objetInteractif = this.accessoireECG
            restApiService.put('/api/accessoires', obj)
          }
        })
      },
      onSauvegarderAppareilVentilation () {
        restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Appareil de ventilation').then((result) => {
          var obj = result.data
          if (obj === '') {
            obj = {}
            obj.scenario = this.scénario
            obj.objetInteractif = this.accessoireVentilation
            restApiService.post('/api/accessoires', obj)
          } else {
            obj.objetInteractif = this.accessoireVentilation
            restApiService.put('/api/accessoires', obj)
          }
        })
      },
      onSauvegarderAppareilPompe () {
        restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Pompe à soluté').then((result) => {
          var obj = result.data
          if (obj === '') {
            obj = {}
            obj.scenario = this.scénario
            obj.objetInteractif = this.accessoirePompe
            restApiService.post('/api/accessoires', obj)
          } else {
            obj.objetInteractif = this.accessoirePompe
            restApiService.put('/api/accessoires', obj)
          }
        })
      },
      onSauvegarderAppareilPatient () {
        restApiService.get('/api/accessoires/scenario/' + this.scénario.id + '/type/Patient').then((result) => {
          var objPatient = result.data
          if (objPatient === '') {
            objPatient = {}
            objPatient.scenario = this.scénario
            objPatient.objetInteractif = this.accessoirePatient
            restApiService.post('/api/accessoires', objPatient)
          } else {
            objPatient.objetInteractif = this.accessoirePatient
            restApiService.put('/api/accessoires', objPatient)
          }
        })
      },
      onSauvegarder () {
        restApiService.put('/api/scenarios', this.scénario)
      },
      onSauvegarderAction () {
        restApiService.put('/api/actions', {
          scenario: {
            id: this.scénario.id,
          },
          ...this.action,
        })
      },
      onCréerFiche () {
        this.$dialog
          .prompt({
            title: "Création d'une nouvelle fiche",
            body: "Quel est l'identifiant que vous souhaitez attribuer à la nouvelle fiche",
          }, {
            promptHelp: 'Écrire dans la boite ci-dessous et cliquer continuer',
            cancelText: 'Fermer',
            okText: 'Continuer',
          })
          .then((r) => {
            restApiService.get('/api/fiches/existe/identifiant/' + r.data).then((result) => {
              if (result.data.existe) {
                this.$dialog.alert({
                  title: 'Erreur',
                  body: 'L\'identifiant existe déjà',
                })
              } else {
                const infoFiche = {
                  identifiant: r.data,
                  titre: 'Une nouvelle fiche',
                  etat: false,
                  pointsConsultation: 0,
                  scenario: {
                    id: this.scénario.id,
                  },
                }
                restApiService.post('/api/fiches', infoFiche).then((result) => {
                  this.chargerScénario().then(() => {
                    this.sélectionFiche = this.scénario.listeFiches.find(f => f.id === result.data.id)
                  })
                })
              }
            })
          },
          )
      },
      onEffacerFiche () {
        this.$dialog.confirm('Êtes-vous certain de vouloir effacer cet élément ?').then(() => {
          restApiService.delete('/api/fiches/' + this.sélectionFiche.id).then(() => {
            this.sélectionFiche = null
            this.chargerScénario()
          })
        })
      },
      onSauvegarderFiche () {
        this.scénario.listeFiches.forEach(element => {
          const info = {
            ...element,
            scenario: {
              id: this.scénario.id,
            },

          }
          restApiService.put('/api/fiches', info)
        })
      },
      onCréerOption () {
        this.$dialog
          .prompt({
            title: "Création d'un nouveau choix",
            body: "Quel est l'identifiant que vous souhaitez attribuer à votre nouveau choix",
          })
          .then((r) => {
            const infoOption = {
              identifiant: r.data,
              texte: '',
              points: 0,
              scriptReactionDecision: '',
              action: {
                ...this.action,
              },
            }
            restApiService.post('/api/action-options', infoOption).then((result) => {
              this.chargerScénario().then(() => {
                this.sélectionOption = this.action.listeOptions.find(f => f.id === result.data.id)
              })
            })
          },
          )
      },
      onSauvegarderOption () {
        this.action.listeOptions.forEach(element => {
          const info = {
            ...element,
          }
          restApiService.put('/api/action-options', info)
        })
      },
      onEffacerOption () {
        this.$dialog.confirm('Êtes-vous certain de vouloir effacer cet élément ?').then(() => {
          restApiService.delete('/api/action-options/' + this.sélectionOption.id).then(() => {
            this.sélectionOption = null
            this.chargerScénario()
          })
        })
      },
      onChangerSélectionFiche () {
        if (this.sélectionFiche.urlVideo) {
          this.choixTypeContenuFiche = 1
        } else {
          this.choixTypeContenuFiche = 0
        }
      },
      async onChangerChoixTypeContenuFiche (choix) {
        if (choix === 0 && this.sélectionFiche.urlVideo) {
          var res = await this.$dialog.confirm(
            {
              title: 'Attention!',
              body: 'Vous tentez de retourner au contenu format riche. Si vous continuez, la l\'url de la vidéo sera supprimée. Souhaitez-vous continuer au format riche ?',
            },
            {
              okText: 'Confirmer',
              cancelText: 'Annuler',
            }).then(function () {
            return true
          }).catch(function () {
            return false
          })
          if (res) {
            this.sélectionFiche.urlVideo = ''
          } else {
            this.choixTypeContenuFiche = 1
          }
        }
      },

    },
  }
</script>
