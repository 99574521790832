<template>
  <div>
    <v-combobox
      ref="categorieFiche"
      v-model="texteLangue"
      item-text="nom"
      :items="itemsTraduit"
      :label="libeleLangue"
      prepend-icon="mdi-translate"
      @click:prepend="changerLangue()"
      @blur="onBlur()"
      @change="onChange($event)"
    />
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      items: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      fr: '',
      en: '',
      selectionLangue: 'fr',
      texteLangue: '',
      domParser: null,
    }),
    computed: {
      libeleLangue: function () {
        return this.selectionLangue === 'fr' ? this.label + ' (Fr)' : this.label + ' (En)'
      },
      itemsTraduit: function () {
        return this.items.map((item) => {
          if (item.startsWith('<t>')) {
            const dom = this.domParser.parseFromString(item, 'application/xml')
            if (this.selectionLangue === 'fr') {
              return { nom: dom.documentElement.getElementsByTagName('fr')[0].textContent, i18n: item }
            } else {
              return { nom: dom.documentElement.getElementsByTagName('en')[0].textContent, i18n: item }
            }
          } else {
            return { nom: item, i18n: item }
          }
        })
      },
    },
    watch: {
      value: {
        handler (newValue) {
          this.onChargerTexte(newValue)
        },
      },
    },
    created () {
      this.domParser = new DOMParser()
      this.onChargerTexte(this.value)
    },
    methods: {
      onChange (nouvelleValeur) {
        if (typeof nouvelleValeur === 'object') {
          this.onChargerTexte(nouvelleValeur.i18n)
        }
      },
      onBlur () {
        this.$refs.categorieFiche.blur()
        this.$nextTick(() => {
          if (this.selectionLangue === 'fr') {
            this.fr = this.texteLangue
          } else { this.en = this.texteLangue }
          const dom = this.domParser.parseFromString('<t><fr></fr><en></en></t>', 'application/xml')
            dom.documentElement.getElementsByTagName('fr')[0].textContent = this.fr
            dom.documentElement.getElementsByTagName('en')[0].textContent = this.en
            this.$emit('update:value', dom.documentElement.outerHTML)
        })
      },
      changerLangue () {
      this.$refs.categorieFiche.blur()
      this.$nextTick(() => {
        if (this.selectionLangue === 'fr') {
          this.fr = this.texteLangue
          this.selectionLangue = 'en'
          this.texteLangue = this.en
        } else {
          this.en = this.texteLangue
          this.selectionLangue = 'fr'
          this.texteLangue = this.fr
        }
      })
      },
      onChargerTexte (newValue) {
        if (newValue != null && newValue.startsWith('<t>')) {
          const dom = this.domParser.parseFromString(newValue, 'application/xml')
          this.fr = dom.documentElement.getElementsByTagName('fr')[0].textContent
          this.en = dom.documentElement.getElementsByTagName('en')[0].textContent
          if (this.selectionLangue === 'fr') {
            this.texteLangue = this.fr
          } else {
            this.texteLangue = this.en
          }
        } else {
          this.fr = newValue
          this.en = newValue
          this.texteLangue = newValue
        }
      },
    },
  }

</script>
