<template>
  <div>
    <v-row>
      <v-col>
        <label class="font-weight-light">{{ libeleLangue }}</label>
      </v-col>
      <v-col class="d-flex flex-row-reverse">
        <v-icon @click="changerLangue()">
          mdi-translate
        </v-icon>
      </v-col>
    </v-row>
    <ckeditor
      id="ckEditorId"
      v-model="ckText"
      :editor="editor"
      :config="editorConfig"
      @input="onInput"
    />
  </div>
</template>

<style>
  .ck-editor__editable {
    min-height: 100px;
  }
  .ck.ck-editor {
    margin-top: 5px;
  }
</style>

<script>

  import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
  import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials'
  import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold'
  import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic'
  import Indent from '@ckeditor/ckeditor5-indent/src/indent'
  import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
  import Link from '@ckeditor/ckeditor5-link/src/link'
  import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph'
  import Heading from '@ckeditor/ckeditor5-heading/src/heading'
  import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter'
  import Image from '@ckeditor/ckeditor5-image/src/image'
  import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle'
  import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert'
  import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize'
  import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage'
  import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar'
  import Table from '@ckeditor/ckeditor5-table/src/table'
  import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'

  export default {
    props: {
      texte: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      editor: ClassicEditor,
      textFr: '',
      textEn: '',
      selectionLangue: 'fr',
      ckText: '',
      editorConfig: {
        plugins: [
          Essentials,
          Bold,
          Italic,
          Link,
          Heading,
          Paragraph,
          Image,
          SimpleUploadAdapter,
          ImageResize,
          ImageInsert,
          AutoImage,
          ImageToolbar,
          ImageStyle,
          Table,
          TableToolbar,
          Indent,
          IndentBlock,
        ],
        toolbar: ['heading', '|', 'bold', 'italic', 'link', '|', 'outdent', 'indent', '|', 'numberedList', 'bulletedList', '|', 'insertImage', 'image', 'insertTable', '|', 'undo', 'redo'],
        link: {
          decorators: {
            openInNewTab: {
              mode: 'manual',
              label: 'Ouvrir dans un nouvel onglet',
              attributes: {
                target: '_blank',
                rel: 'noopener noreferrer',
              },
            },
          },
        },
        image: {
          styles: [
            'alignLeft', 'alignCenter', 'alignRight',
          ],
          resizeOptions: [
            {
              name: 'resizeImage:original',
              label: 'Original',
              value: null,
            },
            {
              name: 'resizeImage:75',
              label: '75%',
              value: '75',
            },
            {
              name: 'resizeImage:50',
              label: '50%',
              value: '50',
            },
          ],
          toolbar: [
            'resizeImage',
            '|',
            'imageTextAlternative',
            '|',
            'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          ],
        },
        simpleUpload: {
          uploadUrl: process.env.VUE_APP_SERVEUR_URL + '/api/ckupload',
          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: true,
          headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            Authorization: 'aaa',
          },
        },
      },
    }),
    computed: {
      libeleLangue: function () {
        return this.selectionLangue === 'fr' ? this.label + ' (Fr)' : this.label + ' (En)'
      },
    },
    watch: {
      texte: {
        handler (newValue) {
          this.onChargerTexte(newValue)
        },
      },
    },
    created () {
      this.onChargerTexte(this.texte)
      const token = `Bearer ${this.$store.state.jwttoken}`
      this.editorConfig.simpleUpload.headers.Authorization = token
    },
    methods: {
      onInput (e) {
        if (this.selectionLangue === 'fr') {
          this.fr = this.ckText
        } else { this.en = this.ckText }
        const parser = new DOMParser()
        const dom = parser.parseFromString('<t><fr></fr><en></en></t>', 'application/xml')
        dom.documentElement.getElementsByTagName('fr')[0].textContent = this.fr
        dom.documentElement.getElementsByTagName('en')[0].textContent = this.en
        this.$emit('update:texte', dom.documentElement.outerHTML)
      },
      changerLangue () {
        if (this.selectionLangue === 'fr') {
          this.selectionLangue = 'en'
          this.ckText = this.en
        } else {
          this.selectionLangue = 'fr'
          this.ckText = this.fr
        }
      },
      onChargerTexte (newValue) {
        if (!newValue) {
          newValue = ''
        }
        if (newValue.startsWith('<t>')) {
          const parser = new DOMParser()
          const dom = parser.parseFromString(newValue, 'application/xml')
          this.fr = dom.documentElement.getElementsByTagName('fr')[0].textContent
          this.en = dom.documentElement.getElementsByTagName('en')[0].textContent
          if (this.selectionLangue === 'fr') {
            this.ckText = this.fr
          } else {
            this.ckText = this.en
          }
        } else {
          this.fr = newValue
          this.en = newValue
          this.ckText = newValue
        }
      },
    },
  }

</script>
